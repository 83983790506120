@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Firefox */
* {
  scrollbar-width:thin;
  scrollbar-color: #a5a5a5 #F6F6F6;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* border-radius: 5px; */
}

*::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 3px;
}
.App canvas:focus {
  outline: none;
}