.react-calendar {
	width: 350px;
	max-width: 100%;
	background: white;
	line-height: 1.125em;
}

.react-calendar--doubleView {
	width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}

.react-calendar button:enabled:hover {
	cursor: pointer;
}

.react-calendar__navigation {
	display: flex;
	height: 25px;
	margin-bottom: 1em;
	gap: 0.5rem;
}

.react-calendar__navigation button {
	min-width: clamp(10px, 20px, 100px);
	background: none;
	font-size: 0.7rem;
	background-color: #f6f6f6;
}

.react-calendar__navigation button:disabled {
	background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
	/* flex gets applied programmatically by the library */
	display: grid !important;
	grid-template-columns: repeat(7, 1fr);
	/*! needs to be same as column-gap in .react-calendar__month-view__days */
	gap: 0.5rem;

	text-align: center;
	text-transform: capitalize;
	text-decoration: none;
	font: inherit;
	font-size: 0.75em;
	font-weight: bold;
}

.react-calendar__month-view__days {
	display: grid !important;
	grid-template-columns: repeat(7, 1fr);

	/*! needs to be same as column-gap in .react-calendar__month-view__weekdays */
	gap: 0.5rem;
}

.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}

/* tailwind adds dotted styling to abbr elements by default */
.react-calendar__month-view__weekdays__weekday > abbr {
	text-decoration: none;
	font-weight: 500;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font: inherit;
	font-size: 0.75em;
	font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
	color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
	color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}

.react-calendar__tile {
	max-width: 100%;
	padding: 10px 6.6667px;
	background: none;
	text-align: center;
	line-height: 16px;
	font: inherit;
	font-size: 0.833em;
}

.react-calendar__tile:disabled {
	background-color: #f0f0f0;
	color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
	color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #e6e6e6;
}

/* current day styling - nothing to apply for now, just leaving it here for reference */
/* .react-calendar__tile--now {
	background: #ffff76;
} */

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #ffffa9;
}

.react-calendar__tile--hasActive {
	background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: #a9d4ff;
}

/* active day styling - nothing to apply for now, just leaving it here for reference */
/* .react-calendar__tile--active {
} */

/* focus day styling - nothing to apply for now, just leaving it here for reference */
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #75757521;
}

.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}

/* *************** CUSTOM STYLES *************** */
.fault {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		bottom: 2px;
		left: 50%;
		transform: translateX(-50%);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #f1ab86;
	}
}

.fault-2 {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		bottom: 2px;
		left: 65%;
		transform: translateX(-65%);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #f1ab86;
	}

	&:before {
		content: "";
		position: absolute;
		bottom: 2px;
		left: 35%;
		transform: translateX(-35%);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #f1ab86;
	}
}
